import http2 from '@/services/http2'
// import router from '@/router'
// import { Message } from 'element-ui'

export default {
  namespaced: true,
  state: () => ({
    rememberedUserInfo: {},
    userInfo: {},
    isLogin: false,
    // login|register
    loginType: 'login',
    showLogin: false,
    showSearch: false,
    showMenu: false,
    menu: 'index'
  }),
  mutations: {
    
    updateData(state, data) {
      // Vue.$set(state, data.name, data.data)
      state[data.name] = data.data;
      if(data.name == 'userInfo') {
        state.isLogin = data.data && JSON.stringify(data.data) !== '{}';
      }
    },
    clearList(state) {
      state.list = []
      state.current = 1
      state.keyword = ''
    },
    switchLogin(state, data) {
      state.showLogin = data.show
      state.loginType = data.type
    },
    logout(state) {
      state.userInfo = {}
      state.isLogin = false
    }
  },
  actions: {
    login({ commit }, data) {
      http2.post('/login', data.data).then((res) => {
        if(res.code == 0) {
          commit('updateData', {
            name: 'userInfo',
            data: res.data
          })
          data.remember && commit('updateData', {
            name: 'rememberedUserInfo',
            data: data
          })
          if('success' in data) typeof data.success == 'function' && data.success()
        }
      })
    },
    register({ commit }, data) {
      http2.post('/register', data.data).then((res) => {
        if(res.code == 0) {
          commit('updateData', {
            name: 'userInfo',
            data: res.data
          })
          data.remember && commit('updateData', {
            name: 'rememberedUserInfo',
            data: data
          })
          if('success' in data) typeof data.success == 'function' && data.success()
        }
      })
    },
    searchList({ commit, dispatch }) {
      commit('updateData', {
        name: 'list',
        data: []
      })
      commit('updateData', {
        name: 'current',
        data: 1
      })
      dispatch('getList')
    },
    getAll({ commit }, data) {
      commit('updateData', {
        name: 'loading',
        data: true
      })
      http2.post(data.url).then((res) => {
        commit('updateData', {
          name: 'loading',
          data: false
        })
        if (res.code == 0) {
          commit("updateData", {
            name: data.name,
            data: res.data.data
          })
        }
      });
    },
    getList({ commit, state }) {
      commit('updateData', {
        name: 'loading',
        data: true
      })
      http2.post('goods/get', {
        page: state.current,
        name: state.keyword
      }).then((res) => {
        commit('updateData', {
          name: 'loading',
          data: false
        })
        if (res.code == 0) {
          commit("updateData", {
            name: 'list',
            data: res.data.data
          })
          commit("updateData", {
            name: 'total',
            data: res.data.total
          })
        }
      });
    }
  }
}