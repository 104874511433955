<template>
  <div class="sg-upload-box">
    <el-upload
      list-type="picture-card"
      :action="uploadUrl"
      :multiple="multiple"
      :limit="limit"
      :file-list="fileList"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :on-exceed="handleExceed"
      :before-upload="beforeUpload"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import { API_FILE_UPLOAD_URL } from '../../services/env.js'

export default {
  name: "SGUpload",
  props: {
    value: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      uploadUrl: API_FILE_UPLOAD_URL,
      dialogVisible: false,
      dialogImageUrl: "",
      maxSize: 10 * 1024 * 1024,
      fileList: [],
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal != oldVal) {
        this.updateFileList();
      }
    },
    fileList() {
      this.updateValue();
    },
  },
  mounted() {
    this.updateFileList();
  },
  methods: {
    beforeUpload(file) {
      var size = file.size;
      if (size > this.maxSize) {
        this.$message({
          type: "error",
          message: "最大上传文件大小为10M",
        });
        return false
      }
    },
    handleExceed() {
      this.$message({
        type: "error",
        message: "最多上传" + this.limit + "个文件",
      });
    },
    handleRemove(_file, fileList) {
      this.fileList = fileList;
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleSuccess(res, _file, fileList) {
      if (res.code == 0) {
        var len = fileList.length;
        fileList[len - 1].path = res.data.path;
        this.fileList = fileList;
      }
    },
    updateValue() {
      var paths = this.fileList.map((val) => {
        return val.path;
      });
      this.$emit("input", paths.join(","));
    },
    checkNeedUpdate() {
      var value = this.value ? this.value.split(",") : [];
      var fileList = this.fileList;
      if (value.length != fileList.length) return true;
      for (var i = 0; i < fileList.length; ++i) {
        if (value.indexOf(fileList[i].path) == -1) return true;
      }
      return false;
    },
    updateFileList() {
      if (!this.checkNeedUpdate()) return false;
      var value = this.value ? this.value.split(",") : [];
      var fileList = [];
      value = value.filter((item) => item);
      for (var i = 0; i < value.length; ++i) {
        var name = this.getFileName(value[i]);
        fileList.push({
          name: name,
          path: value[i],
          url: value[i],
        });
      }
      this.fileList = fileList;
    },
    getFileName(url) {
      var items = url.split("/");
      return items.length > 0 ? items[items.length - 1] : "";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
