<template>
  <div>
    <div class="sg-flex-wrap">
      <div v-for="(item, index) in files" :key="index" class="sg-upload-item">
        <img :src="item" class="sg-upload-img" />
        <div class="sg-upload-wrap sg-gray-mask-bg">
          <div class="sg-flex-center sg-white sg-h100 sg-icon-actions">
            <i
              class="el-icon-zoom-in sg-margin-right sg-pointer"
              @click="preview(index)"
            ></i>
            <i class="el-icon-delete sg-pointer" @click="deleteFile(item)"></i>
          </div>
        </div>
      </div>
      <div class="sg-upload-item sg-flex-center sg-upload-btn sg-pointer" @click="switchShowSelect(true)">
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <SelectFile :show="showSelect" @close="switchShowSelect(false)" @confirm="confirm($event)" :limit="limit"></SelectFile>
    <PreviewCard :files="files" :index="previewIndex" :show="showPreview" @close="switchShowPreview(false)" @updateIndex="updateIndex($event)"></PreviewCard>
  </div>
</template>
<script>

import PreviewCard from "@/components/Share/PreviewCard.vue"
import SelectFile from "@/components/Share/SelectFile.vue"

export default {
  name: "UploadCard",
  components: {
    PreviewCard,
    SelectFile
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    limit: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    files() {
      var val = this.value ? this.value : ''
      var files = val.split(",");
      return files.filter((item) => item);
    },
  },
  data: () => {
    return {
      showPreview: false,
      showSelect: false,
      previewIndex: 0,
    };
  },
  methods: {
    confirm(data) {
      if(data.length <= 0) return false;
      var files = this.limit <= 1 ? data : this.files.concat(data)
      files = files.slice(0, this.limit)
      this.$emit('input', files.join(','))
    },
    switchShowSelect(show) {
      this.showSelect = show
    },
    deleteFile(url) {
      var items = this.files.filter((item) => (item != url));
      var files = items.join(',')
      this.$emit('input', files)
    },
    preview(index) {
      this.previewIndex = index;
      this.switchShowPreview(true);
    },
    switchShowPreview(show) {
      this.showPreview = show;
    },
    updateIndex(data) {
      this.previewIndex = data
    }
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/global.scss";

.sg-upload-img,
.sg-upload-wrap,
.sg-upload-item {
  width: $sg-upload-width;
  height: $sg-upload-width;
  border-radius: 5px;
}
.sg-upload-item {
  margin: calc($pad / 2);
  position: relative;
  border: 1px solid $border-color;
}
.sg-upload-img,
.sg-upload-wrap {
  position: absolute;
  left: 0;
  right: 0;
}
.sg-upload-wrap {
  visibility: hidden;
}
.sg-upload-item:hover .sg-upload-wrap {
  visibility: visible;
}
.sg-icon-actions {
  font-size: $font-icon;
}
.sg-upload-btn {
  border: 1px dashed $border-color;
  font-size: $font-icon;
}
</style>
