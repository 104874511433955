<template>
  <div>
    <el-row>
      <el-col :sm="{ span: 16 }">
        <vue-cropper
          ref="cropper"
          :src="src"
          :aspect-ratio="aspectRatio"
          @ready="ready"
          @crop="cropImage"
          :autoCrop="true"
        ></vue-cropper>
      </el-col>
      <el-col :sm="{ span: 8 }">
        <div class="cropped-image sg-pad-left sg-pad-bottom">
          <img
            v-if="cropUrl"
            :src="cropUrl"
            alt="Cropped Image"
            class="sg-w100"
          />
          <div v-else class="crop-placeholder" />
        </div>
        <el-form ref="form" label-width="80px">
          <el-form-item label="宽高比">
            <el-col :sm="{ span: 11 }">
              <el-input v-model="aspectRatioWidth"></el-input>
            </el-col>
            <el-col class="sg-text-center" :sm="{ span: 2 }">:</el-col>
            <el-col :sm="{ span: 11 }">
              <el-input v-model="aspectRatioHeight"></el-input>
            </el-col>
            <el-col :sm="{ span: 24 }" class="sg-pad-top">
              <el-button-group>
                <el-button type="success" @click="updateAspectRatio(16, 9)"
                  >16:9</el-button
                >
                <el-button type="success" @click="updateAspectRatio(4, 3)"
                  >4:3</el-button
                >
                <el-button type="success" @click="updateAspectRatio(1, 1)"
                  >1:1</el-button
                >
                <el-button type="success" @click="updateAspectRatio(2, 3)"
                  >2:3</el-button
                >
                <el-button type="success" @click="updateAspectRatio(null, null)"
                  >Free</el-button
                >
              </el-button-group>
            </el-col>
          </el-form-item>
          <el-form-item label="上传选项">
            <el-radio-group v-model="uploadType">
              <el-radio label="new" border>上传为新文件</el-radio>
              <el-radio label="cover" border>覆盖原文件</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="upload">上传裁剪</el-button>
            <el-button type="info" @click="back">返回媒体库</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { API_FILE_UPLOAD_URL } from "@/services/env.js";

export default {
  name: "EditImage",
  components: {
    VueCropper,
  },
  props: {
    src: {
      type: String,
      value: "http://app.vuee/storage/uploadhttps://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/20220213/620900e673db0.png",
    },
  },
  data: () => {
    return {
      aspectRatio: null,
      cropUrl: "",
      uploadType: "new",
      aspectRatioWidth: "",
      aspectRatioHeight: "",
      url: "",
      loading: false,
    };
  },
  computed: {},
  methods: {
    back() {
      this.$emit('close')
    },
    upload() {
      var croppedCanvas = this.$refs.cropper.getCroppedCanvas();
      if(!croppedCanvas) return false;
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        const data = new FormData();
        data.append("file", blob);
        this.loading = true;
        this.$http
          .post(API_FILE_UPLOAD_URL, data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.loading = false;
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            }
          });
      });
    },
    updateAspectRatio(width, height) {
      if (width && height) {
        this.aspectRatioWidth = width;
        this.aspectRatioHeight = height;
        this.aspectRatio = width / height;
      } else {
        this.aspectRatioWidth = "";
        this.aspectRatioHeight = "";
        this.aspectRatio = null;
      }
      this.$refs.cropper.setAspectRatio(this.aspectRatio);
    },
    ready() {
      this.cropImage();
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      var croppedCanvas = this.$refs.cropper.getCroppedCanvas()
      if(croppedCanvas) this.cropUrl = croppedCanvas.toDataURL();
      // this.cropUrl = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/global.scss";

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}
</style>