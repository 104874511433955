<template>
  <el-tag :type="tag.type">{{ tag.label }}</el-tag>
</template>

<script>
export default {
  name: 'StatusTag',
  props: {
    tags: {
      type: Array,
      value: []
    },
    status: {
      type: Number,
      value: 1
    }
  },
  data: () => {
    return {}
  },
  computed: {
    tag() {
      var tags = this.tags
      for(var i = 0; i < tags.length; ++i) {
        if(tags[i].key == this.status) return tags[i]
      }
      return {}
    }
  },
  methods: {},
  mounted() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/global.scss";

</style>
