import Vue from "vue";
import Vuex from "vuex";
// import http from '../services/http'
// import router from '../router'

import admin from "./modules/admin";
import web from "./modules/web";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        loading: false,
        // xl: >=1920px, lg: >= 1200px, md: >= 992px, sm: >= 768px, xs: < 768px
        screenSize: "lg",
        touchTop: false,
        scrollTop: 0,
        scrollNavDown: false,
        scrollY: 0
    },
    mutations: {
        scrollAction(state) {
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;

            let touchTop = scrollTop < 1;
            state.touchTop = touchTop;
            state.scrollTop = scrollTop;
            state.scrollNavDown = scrollTop > 10;
        },
        updateData(state, data) {
            state[data.name] = data.data;
            if (data.name == "userInfo") {
                state.isLogin = data.data && JSON.stringify(data.data) !== "{}";
            }
        },
        updateLoading(state, data) {
            state.loading = data;
        },
        updateScreenSize(state) {
            let width = document.body.clientWidth,
                screenSize = "lg";
            if (width >= 1920) screenSize = "xl";
            else if (width >= 1200) screenSize = "lg";
            else if (width >= 992) screenSize = "md";
            else if (width >= 768) screenSize = "sm";
            else screenSize = "xs";
            state.screenSize = screenSize;
        },
    },
    actions: {},
    modules: {
        admin: admin,
        web: web,
    },
});
