var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{staticClass:"sg-preview-box sg-fix-screen sg-gray-mask-bg sg-border-box"},[_c('i',{staticClass:"el-icon-close sg-icon-btn sg-pointer sg-absolute sg-preview-close",on:{"click":_vm.close}}),_c('i',{staticClass:"el-icon-arrow-left sg-icon-btn sg-pointer sg-absolute sg-preview-prev",on:{"click":function($event){return _vm.updateIndex(_vm.index - 1)}}}),_c('i',{staticClass:"el-icon-arrow-right sg-icon-btn sg-pointer sg-absolute sg-preview-next",on:{"click":function($event){return _vm.updateIndex(_vm.index + 1)}}}),_c('div',{staticClass:"sg-action-bottom sg-absolute"},[_c('i',{staticClass:"el-icon-zoom-out sg-pad-lr sg-pointer",on:{"click":function($event){return _vm.zoom(_vm.scale - 0.2)}}}),_c('i',{staticClass:"el-icon-zoom-in sg-pad-lr sg-pointer",on:{"click":function($event){return _vm.zoom(_vm.scale + 0.2)}}}),_c('i',{class:{
        'sg-pad-lr sg-pointer': true,
        'el-icon-full-screen': _vm.previewType == 'origin',
        'el-icon-c-scale-to-original': _vm.previewType == 'fullscreen',
      },on:{"click":_vm.switchPreviewType}}),_c('i',{staticClass:"el-icon-refresh-left sg-pad-lr sg-pointer",on:{"click":function($event){return _vm.rotate(_vm.degree - 90)}}}),_c('i',{staticClass:"el-icon-refresh-right sg-pad-lr sg-pointer",on:{"click":function($event){return _vm.rotate(_vm.degree + 90)}}})]),_c('div',{staticClass:"sg-flex-center sg-h100 sg-w100"},[_c('img',{class:{
        'sg-origin': _vm.previewType == 'origin',
        'sg-fullscreen': _vm.previewType == 'fullscreen',
      },style:({
        transform: 'scale(' + _vm.scale + ') rotate(' + _vm.degree + 'deg)',
        'margin-left': _vm.offsetX + 'px',
        'margin-top': _vm.offsetY + 'px',
      }),attrs:{"src":_vm.files[_vm.index]},on:{"mousedown":function($event){return _vm.updateMouseStatus($event, 'down')},"mouseup":function($event){return _vm.updateMouseStatus($event, 'up')},"mousemove":function($event){return _vm.move($event)}}})])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }