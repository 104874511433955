import Vue from 'vue'
import App from './App.vue'
import router from './router'
import TabRouter from './router/TabRouter'
import store from './store'
import http from './services/http'
import http2 from './services/http2'
import util from './services/util'
import tab from './services/tab'
import {
  Button,
  ButtonGroup,
  Link,
  Card,
  Form,
  FormItem,
  Input,
  Row,
  Col,
  Checkbox,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Tooltip,
  Table,
  TableColumn,
  Divider,
  Pagination,
  Dialog,
  Message,
  MessageBox,
  Loading,
  Notification,
  Upload,
  Radio,
  RadioGroup,
  RadioButton,
  Carousel,
  CarouselItem,
  Badge,
  Tag,
  Tree,
  TabPane,
  Tabs,
  Select,
  Option,
  Popover,
  InputNumber,
  Drawer,
  Popconfirm,
  Cascader,
  Dropdown,
  DropdownMenu,
  DropdownItem
} from 'element-ui';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

import SGUpload from './components/Share/sg-upload'
import UploadCard from './components/Share/UploadCard'
import StatusTag from "@/components/Share/StatusTag.vue";

import 'element-ui/lib/theme-chalk/display.css';

// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, LineChart, PieChart } from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  /*eslint no-unused-vars: ["error", { "varsIgnorePattern": "DatasetComponentOption" }]*/
  DatasetComponentOption,
  TransformComponent,
  LegendComponent,
  ToolboxComponent,
} from 'echarts/components';
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';
//import animate from "animate.css";
// 注册必须的组件
echarts.use([
  BarChart,
  LineChart,
  PieChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent,
  ToolboxComponent
]);

import VueLazyload from 'vue-lazyload'


// import './style.css';

Vue.use(VueLazyload)

Vue.component('sg-upload', SGUpload)
Vue.component('UploadCard', UploadCard)
Vue.component('StatusTag', StatusTag)
Vue.component(CollapseTransition.name, CollapseTransition)

Vue.config.productionTip = false
Vue.prototype.$http = http
Vue.prototype.$http2 = http2
Vue.prototype.$util = util
Vue.prototype.$tab = tab
Vue.prototype.$echarts = echarts

Vue.filter('formatDate', util.formatDate)
Vue.filter('fileSize', util.fileSize)
Vue.filter('webAvatar', util.webAvatar)

Vue.use(Cascader)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Link)
Vue.use(Card)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Row)
Vue.use(Col)
Vue.use(Checkbox)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Tooltip)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Divider)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Upload)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(RadioButton)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Badge)
Vue.use(Tag)
Vue.use(Tree)
Vue.use(TabPane)
Vue.use(Tabs)
Vue.use(Select)
Vue.use(Option)
Vue.use(Popover)
Vue.use(InputNumber)
Vue.use(Drawer)
Vue.use(Popconfirm)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)


Vue.use(Loading.directive);

Vue.use(TabRouter)

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
//Vue.use(animate)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
