import http from '@/services/http'
import router from '@/router'

export default {
  namespaced: true,
  state: () => ({
    rememberedUserInfo: {},
    userInfo: {},
    isLogin: false,
    admin_menus: [],
    categories: []
  }),
  mutations: {
    updateList(state, data) {
      state[data.key] = data.data
    },
    updateData(state, data) {
      state[data.name] = data.data;
      if(data.name == 'userInfo') {
        state.isLogin = data.data && JSON.stringify(data.data) !== '{}';
      }
    },
    logout(state) {
      state.userInfo = {}
      state.isLogin = false
      router.push({
        path: "/admin/login",
      });
    }
  },
  actions: {
    login({ commit }, data) {
      http.post('/login', data).then((res) => {
        if(res.code == 0) {
          commit('updateData', {
            name: 'userInfo',
            data: res.data
          })
          var rememberInfo = data.remember ? data : {}
          commit('updateData', {
            name: 'rememberedUserInfo',
            data: rememberInfo
          })
          router.push({
            path: '/admin'
          })
        }
      })
    },
    getList({ commit }, data) {
      
      commit("updateLoading", true, { root: true });
      http.post(data.url, data.data).then((res) => {
        commit("updateLoading", false, { root: true });
        if (res.code == 0) {
          commit("updateList", {
            key: data.key,
            data: res.data
          })
          data.cb && data.cb(res)
        }
      });
    }
  }
}