import axios from "axios";
import { Message } from "element-ui";
import store from "@/store";
// import qs from 'qs';
import { API_ADMIN_URL } from "../services/env";

// if(!window.Promise) {
//   window.Promise = Promise;
// }

// create an axios instance
const http = axios.create({
    baseURL: API_ADMIN_URL, // url = base url + request url
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 20000, // request timeout
});

http.imagePrefix = "http://zjxcx.zjxc358.com";

// const originApis = ['article/canRead'];

// request interceptor
http.interceptors.request.use(
    (config) => {
        // do something before request is sent
        // if(['upload/addFile', 'upload/batchFile'].indexOf(config.url) == -1) {
        //   if(store.state.userInfo) {
        //     config.data = Object.assign({}, config.data, {uid: store.state.userInfo.id})
        //     // config.data = Object.assign({}, config.data, {uid: 1})
        //   }
        //   config.data = qs.stringify(config.data);
        // }
        if (store.state.admin.userInfo && store.state.admin.userInfo.token) {
            // let each request carry token --['X-Token'] as a custom key.
            // please modify it according to the actual situation.
            config.headers["X-Token"] = store.state.admin.userInfo.token;
        }
        // if(originApis.indexOf(config.url) != -1) {
        //   config.baseURL = 'https://fuxing.guduokeji.cn/apipc/'
        // }
        return config;
    },
    (error) => {
        // do something with request error
        // console.log(error) // for debug
        return Promise.reject(error);
    }
);

// response interceptor
http.interceptors.response.use(
    /**
     * If you want to get information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code.
     */
    (response) => {
        const res = response.data;
        // if the custom code is not 20000, it is judged as an error.
        store.commit("updateLoading", false);
        if (res.code !== 0) {
            // console.log('here')
            Message({
                message: res.msg || "error",
                type: "error",
                duration: 2 * 1000,
                onClose: function () {
                    // -2: 重新登录;
                    if (res.code === -2) {
                        // to re-login
                        window.location.href = "/admin/login";
                    }
                },
            });

            return Promise.reject(res.msg || "error");
        } else {
            return res;
        }
    },
    (error) => {
        // console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: "error",
            duration: 5 * 1000,
        });
        return Promise.reject(error);
    }
);

export default http;
