const baseUrl = process.env.VUE_APP_BASE_URL
const API_FILE_UPLOAD_URL = baseUrl + 'api/upload'
const TEMPLATE_URL =  baseUrl + 'templates/'
const API_ADMIN_URL = baseUrl + 'api/admin/'
const API_WEB_URL = baseUrl + 'api/web/'

export {
  API_ADMIN_URL,
  API_FILE_UPLOAD_URL,
  TEMPLATE_URL,
  API_WEB_URL
}