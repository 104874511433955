<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// @ is an alias to /src
export default {
  name: "App",
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {
    scrolling() {
      this.$store.commit("scrollAction");
      //   let scrollTop =
      //     window.pageYOffset ||
      //     document.documentElement.scrollTop ||
      //     document.body.scrollTop;

      //   let touchTop = scrollTop < 1;
      //   this.$store.commit("updateData", {
      //     name: "touchTop",
      //     data: touchTop,
      //   });
      //   this.$store.commit("updateData", {
      //     name: "scrollTop",
      //     data: scrollTop,
      //   });
      //   // console.log(scrollTop)
      //   this.$store.commit("updateData", {
      //     name: "scrollNavDown",
      //     data: (scrollTop > 10),
      //   });
      // console.log(scrollTop)
    },
  },
  created() {
    var storeKey = "sg-store-" + process.env.VUE_APP_NAME;

    // 在页面加载时读取localStorage里的状态信息
    if (localStorage.getItem(storeKey)) {
      var state = JSON.parse(localStorage.getItem(storeKey));
      if (!state.userInfo || JSON.stringify(state.userInfo) === "{}") {
        state.isLogin = false;
      }
      state.web.showSearch = false;
      state.web.showMenu = false;
      this.$store.replaceState(Object.assign({}, this.$store.state, state));
      this.$store.commit("updateScreenSize");
    }

    var that = this;
    window.addEventListener("resize", () => {
      that.$store.commit("updateScreenSize");
    });

    // 在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener("beforeunload", () => {
      // if (this.$store.state.admin.isLogin) {
      var data = Object.assign({}, this.$store.state, { loading: false });
      data.web.list = [];
      data.web.keyword = "";
      data.web.current = 1;
      localStorage.setItem(storeKey, JSON.stringify(data));
      // }
    });

    window.addEventListener("scroll", this.scrolling);
    // this.scrolling();
    // let path = this.$route.path
    // this.$util.updateMenu(path)

    
  },
};
</script>
<style lang="scss">
@import "./assets/scss/global.scss";
@import "https://at.alicdn.com/t/font_2547197_1n37rjpio4m.css";
body {
  min-width: 1440px;
}
// .el-tree-node {
//   background: $tree-hover-bg;
//   padding: $pad-sm;
//   margin-bottom: $pad-sm;
// }
// .el-tree .el-tree-node > .el-tree-node__children {
//   background: white;
// }
[v-cloak] {
  display: none;
}
@font-face {
  font-family: "Helvetica";
  src: url("/fonts/Helvetica Bold.ttf") format("ttf");
  font-stretch: "normal";
  font-weight: "normal";
}
@font-face {
  font-family: "Helvetica regular";
  src: url("/fonts/helveticaregular.ttf") format("ttf");
  font-stretch: "normal";
  font-weight: "normal";
}
@font-face {
  font-family: "Humnst777 BlkCn BT Black";
  src: url("/fonts/Humnst777 BlkCn BT Black.ttf") format("ttf");
  font-stretch: "normal";
  font-weight: "normal";
}

// div,
// span,
// p {
//   caret-color: transparent;
// }
@each $name, $second in (2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22) {
  // .sg-fade-down-0p#{"" + $name + '-enter'} {
  //   transform: translateY(-50px);
  //   opacity: 0;
  // }
  // .sg-fade-up-0p#{"" + $name + '-enter'} {
  //   transform: translateY(50px);
  //   opacity: 0;
  // }
  // .sg-fade-right-0p#{"" + $name + '-enter'} {
  //   transform: translateX(-50px);
  //   opacity: 0;
  // }
  // .sg-fade-left-0p#{"" + $name + '-enter'} {
  //   transform: translateX(50px);
  //   opacity: 0;
  // }
  // .sg-fade-in-0p#{"" + $name + '-enter'} {
  //   opacity: 0;
  // }
  // .sg-fade-down-0p#{"" + $name + '-enter-active'},
  // .sg-fade-up-0p#{"" + $name + '-enter-active'},
  // .sg-fade-right-0p#{"" + $name + '-enter-active'},
  // .sg-fade-left-0p#{"" + $name + '-enter-active'},
  // .sg-fade-in-0p#{"" + $name + '-enter-active'} {
  //   transition: all 1s ease #{calc($name / 5) + 's'};
  // }
  .sg-fade-down-0p#{"" + $name + "-enter-active"} {
    animation-name: sg-fade-down-ani;
    animation-duration: 1s;
    animation-delay: #{calc($name / 5) + "s"};
    animation-fill-mode: both;
  }
  .sg-fade-up-0p#{"" + $name + "-enter-active"} {
    animation-name: sg-fade-up-ani;
    animation-duration: 1s;
    animation-delay: #{calc($name / 5) + "s"};
    animation-fill-mode: both;
  }
  .sg-fade-up-0p#{"" + $name + "-leave-active"} {
    animation-name: sg-fade-up-ani-leave;
    animation-duration: 1s;
    animation-delay: #{calc($name / 5) + "s"};
    animation-fill-mode: both;
  }
  .sg-fade-left-0p#{"" + $name + "-enter-active"} {
    animation-name: sg-fade-left-ani;
    animation-duration: 1s;
    animation-delay: #{calc($name / 5) + "s"};
    animation-fill-mode: both;
  }
  .sg-fade-right-0p#{"" + $name + "-enter-active"} {
    animation-name: sg-fade-right-ani;
    animation-duration: 1s;
    animation-delay: #{calc($name / 5) + "s"};
    animation-fill-mode: both;
  }
  .sg-fade-in-0p#{"" + $name + "-enter-active"} {
    animation-name: sg-fade-in-ani;
    animation-duration: 1s;
    animation-delay: #{calc($name / 5) + "s"};
    animation-fill-mode: both;
  }
  .sg-fade-out-0p#{"" + $name + "-enter-active"} {
    animation-name: sg-fade-out-ani;
    animation-duration: 1s;
    animation-delay: #{calc($name / 5) + "s"};
    animation-fill-mode: both;
  }
}

@each $name,
  $second
    in (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20)
{
  .sg-fade-up-0p#{"" + $name + "-new-enter-active"} {
    animation-name: sg-fade-up-ani;
    animation-duration: 1s;
    animation-delay: #{calc($name / 10) + "s"};
    animation-fill-mode: forwards;
    opacity: 0;
  }
  .sg-fade-up-0p#{"" + $name + "-new-leave-active"} {
    animation-name: sg-fade-up-ani-leave;
    animation-duration: 1s;
    animation-delay: #{calc($name / 10) + "s"};
    animation-fill-mode: both;
  }
}
.sg-fade-down-menu-enter-active {
  animation-name: sg-fade-down-ani-menu;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}
.sg-fade-up-menu-leave-active {
  animation-name: sg-fade-up-ani-leave-menu;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}
.sg-fade-left-menu-search-enter-active {
  animation-name: sg-fade-left-ani;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: both;
}
.sg-fade-left-0p12-new-enter-active {
  animation-name: sg-fade-left-ani;
  animation-duration: 1s;
  animation-delay: 1.2s;
  animation-fill-mode: forwards;
  opacity: 0;
}
.sg-fade-up-0p6-new-enter-active {
  animation-name: sg-fade-up-ani;
  animation-duration: 1s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
  opacity: 0;
}
.sg-fade-up-0p8-new-enter-active {
  animation-name: sg-fade-up-ani;
  animation-duration: 1s;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
  opacity: 0;
}
.sg-fade-right-blue-enter-active {
  animation-name: sg-fade-right-blue;
  animation-duration: 0.2s;
  animation-delay: 0;
  animation-fill-mode: both;
}
.sg-fade-in-2-enter-active {
  animation-name: sg-fade-in-ani;
  animation-duration: 2s;
  animation-delay: 0;
  animation-fill-mode: both;
}
.sg-slide-in-out-enter-active {
  animation-name: sg-slide-in-out;
  animation-duration: 1s;
  animation-delay: 0;
  animation-fill-mode: both;
}
.sg-fade-out-1-leave-active {
  animation-name: sg-fade-out-ani;
  animation-duration: 1s;
  animation-delay: 0;
  animation-fill-mode: both;
}

.sg-fade-left-0p5-8-enter-active {
  opacity: 0;
  animation: sg-fade-left-700 0.5s ease 0.8s forwards;
}
.sg-fade-left-1-1p05-enter-active {
  opacity: 0;
  animation: sg-fade-left-700 1s ease 1.05s forwards;
}
.sg-fade-left-1-1p10-enter-active {
  opacity: 0;
  animation: sg-fade-left-700 1s ease 1.1s forwards;
}
.sg-fade-left-1-1p15-enter-active {
  opacity: 0;
  animation: sg-fade-left-700 1s ease 1.15s forwards;
}
.sg-fade-left-1-1p20-enter-active {
  opacity: 0;
  animation: sg-fade-left-700 1s ease 1.2s forwards;
}
// #sg-index-drawer {
//   &.el-drawer-fade-enter-active,
//   .el-drawer__open .el-drawer.rtl {
//     animation: sg-fade-in-ani 2s ease-in-out 2s forwards;
//   }
// }
// @keyframes drawer-open {
//   0% {
//     transform: translateX(100%);
//     opacity: 0;
//   }

//   100% {
//     opacity: 1;
//     transform: translateX(0);
//   }
// }
@keyframes sg-fade-left-700 {
  0% {
    opacity: 0;
    transform: translate3d(700px, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes sg-slide-in-out {
  0% {
    transform: translateX(-1200px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes sg-fade-right-blue {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes sg-fade-up-ani {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes sg-fade-up-ani-leave {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}
@keyframes sg-fade-up-ani-leave-menu {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
@keyframes sg-fade-down-ani {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes sg-fade-down-ani-menu {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes sg-fade-left-ani {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes sg-fade-right-ani {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes sg-fade-in-ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes sg-fade-out-ani {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.sg-fade-enter-active,
.sg-fade-leave-active {
  transition: opacity 0.7s;
}
.sg-fade-enter, .sg-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.sg-fade-star-enter-active,
.sg-fade-star-leave-active {
  transition: opacity 0.7s;
}
.sg-fade-star-enter, .sg-fade-star-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.sg-trans-gray {
  background-color: rgba(0, 0, 0, 0.5);
}
.sg-nowrap {
  white-space: nowrap;
}
.sg-menu-trans {
  transition: $menu-transition;
}
.sg-rotate {
  transform: rotate(180deg);
}
.sg-overflow-scroll-y {
  overflow-y: auto;
  max-height: 78vh;
}
.sg-float-right {
  float: right;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.el-select {
  width: 100%;
}
.sg-border {
  border: $border;
}
.sg-border-no {
  border-width: 0 !important;
}
.el-tree .el-tree-node__content {
  background: $tree-bg;
  margin-bottom: $pad-sm;
  padding: $pad-sm;

  &:hover {
    background: $tree-bg-hover;
  }
}
#app {
  font-family: "PingFang SC", "Microsoft YaHei", Avenir, Helvetica, Arial,
    sans-serif;
  // font-family: "Microsoft YaHei", Avenir, Helvetica, Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
}
.sg-font-hel {
  font-family: Helvetica;
}
.sg-font-hel-regular {
  font-family: "Helvetica regular";
}
.sg-font-hum {
  font-family: "Humnst777 BlkCn BT Black";
}
.sg-text-justify {
  text-align: justify;
}
body {
  padding: 0 !important;
  margin: 0;
  width: 100% !important;
  height: 100%;
  overflow: auto !important;
}
.sg-pagination {
  margin-top: $pad;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sg-logo {
  width: $logo-width;

  @media screen and(max-width: $width-xs) {
    width: 100%;
  }
}
.sg-border-box {
  box-sizing: border-box;
}
.sg-flex {
  display: flex;
}
.sg-flex-column {
  @extend .sg-flex;
  flex-direction: column;
}
.sg-flex-wrap {
  @extend .sg-flex;
  flex-wrap: wrap;
}
.sg-flex-end {
  @extend .sg-flex;
  align-items: flex-end;
}
.sg-flex-end-content {
  @extend .sg-flex;
  justify-content: flex-end;
}
.sg-align-center {
  @extend .sg-flex;
  align-items: center;
}
.sg-text-right {
  text-align: right;
}
.sg-flex-center {
  @extend .sg-align-center;
  justify-content: center;
}
.sg-space-between {
  @extend .sg-align-center;
  justify-content: space-between;
}
.sg-space-around {
  @extend .sg-align-center;
  justify-content: space-around;
}
.sg-flex-grow {
  flex-grow: 1;
}
.sg-pad {
  padding: $pad;
}
.sg-margin {
  margin: $pad;
}
.sg-margin-sm {
  margin: $pad-sm;
}
.sg-pad-md {
  padding: $pad-md;
}
.sg-pad-lg {
  padding: $pad-lg;
}
.sg-pad-lg-lr {
  padding: 0 $pad-lg;
}
.sg-margin-lg-tb {
  margin: $pad-lg 0;
}
@each $dir in top, bottom, left, right {
  .sg-pad-#{$dir} {
    padding-#{$dir}: $pad;
  }
  .sg-margin-#{$dir} {
    margin-#{$dir}: $pad;
  }
  .sg-margin-#{$dir}-sm {
    margin-#{$dir}: $pad-sm;
  }
  .sg-pad-#{$dir}-sm {
    padding-#{$dir}: $pad-sm;
  }
  .sg-margin-#{$dir}-md {
    margin-#{$dir}: $pad-md;
  }
  .sg-pad-#{$dir}-md {
    padding-#{$dir}: $pad-md;
  }
  .sg-pad-#{$dir}-lg {
    padding-#{$dir}: $pad-lg;
  }
  .sg-margin-#{$dir}-lg {
    padding-#{$dir}: $pad-lg;
  }
  .sg-border-#{$dir} {
    border-#{$dir}: $border;
  }
}
.sg-margin-tb {
  margin-top: $pad;
  margin-bottom: $pad;
}
.sg-pad-xs {
  padding: $pad-xs;
}
.sg-pad-sm {
  padding: $pad-sm;
}
.sg-pad-tb {
  padding-top: $pad;
  padding-bottom: $pad;
}
.sg-pad-tb-none {
  padding-top: 0;
  padding-bottom: 0;
}
.sg-pad-lr {
  padding-left: $pad;
  padding-right: $pad;
}
.sg-pad-lr-sm {
  padding-left: $pad-sm;
  padding-right: $pad-sm;
}
.sg-pad-tb-sm {
  padding-top: $pad-sm;
  padding-bottom: $pad-sm;
}
.sg-pad-top-sm {
  padding-top: $pad-sm;
}
.sg-list-item {
  padding: $pad-xs $pad;
  cursor: pointer;
}
.sg-list-item .sg-name:hover {
  color: $red;
}
.sg-list-item:hover {
  background: $list-item-active-bg;
}

.sg-badge-xs {
  border-radius: $pad-xs;
}
.sg-main-color {
  color: $main-color;
}
.sg-wechat-color {
  color: $wechat-color;
}
.sg-ali-color {
  color: $ali-color;
}
.sg-light-blue {
  color: $light-blue;
}
.sg-light-blue-bg {
  background: $light-blue;
}
@each $size
  in (
    12px,
    13px,
    14px,
    15px,
    16px,
    17px,
    18px,
    19px,
    20px,
    23px,
    24px,
    25px,
    26px,
    28px,
    29px,
    30px,
    36px,
    39px,
    40px,
    42px,
    45px,
    47px
  )
{
  .sg-font-#{"" + $size} {
    font-size: $size;
  }
}
@each $name,
  $color
    in (
      // gray: #999,
      gray:
        #444,
      light-gray: #f4f4f4,
      dark-gray: #4d4d4d,
      gray-2: $gray-2,
      gray-3: $gray-3,
      gray-4: $gray-4,
      gray-5: $gray-5,
      gray-6: $gray-6,
      gray-footer: #373737,
      gray-4d: $gray-4d,
      gray-b0: $gray-b0,
      gray-8a: #8a8a8a,
      gray-a6: #a6a6a6,
      gray-45: #454545,
      gray-d0: #d0d0d0,
      gray-6a: #6a6a6a,
      gray-df: #dfdfdf,
      gray-6e6d6d: #6e6d6d,
      gray-41: #414141,
      gray-44: #444444,
      gray-b1: #b1b1b1,
      gray-787777: #787777,
      gray-d9d8d8: #d9d8d8,
      gray-54: #545454,
      gray-cbc4c0: #cbc4c0,
      gray-67: #676767,
      gray-b3: #b3b3b3,
      gray-31: #313131,
      gray-85: #858585,
      gray-59504c: #59504c,
      gray-3c3c48b3: #3c3c48b3,
      gray-ceced1: #ceced1,
      gray-e4dfda: #e4dfda,
      gray-b0aea2: #b0aea2,
      gray-feffff: #feffff,
      gray-51: #515151,
      gray-eff8fe: #eff8fe,
      gray-af: #afafaf,
      gray-8e: #8e8e8e,
      red: $red,
      red-c73437: #c73437,
      red-c31a1f: #c31a1f,
      white: #fff,
      black: #000,
      gray-mask: rgba(0, 0, 0, 0.5),
      orange: #fba95f,
      orange-2: $orange-2,
      orange-3: #d67b7b,
      orange-ff9300: #ff9300,
      blue: $blue,
      blue-1340b5: #1340b5,
      blue-0087fd: #0087fd,
      green-91bea6: #91bea6,
      green-48705a: #48705a,
      green-a0cfb6: #a0cfb6,
      gray-c6c6c5: #c6c6c5
    )
{
  .sg-#{"" + $name} {
    color: #{$color};

    &.el-link.el-link--default {
      color: #{$color};
    }
  }
  .sg-#{"" + $name}-bg {
    background: #{$color};
  }
}
.sg-hover-gray-bg:hover {
  background: $border-color;
}
.sg-hover-red:hover {
  color: $red;
}
.sg-pointer {
  cursor: pointer;
}
.sg-height-100 {
  height: 100%;
}
.sg-search-input input,
.sg-search-input input:hover,
.sg-search-input input:focus {
  border-color: $red;
}
.sg-search-input .el-input-group__append {
  background: $red;
  color: white;
  border-color: $red;
  cursor: pointer;
}
.sg-btn {
  padding: $btn-pad;
  border: $border;
  font-size: $font;
  cursor: pointer;
  color: $btn-color;
}
.sg-card {
  background: white;
  box-sizing: border-box;
}

.sg-card-4 {
  @extend .sg-card;
  width: calc((100% - #{$pad}) / 4);
  margin-left: calc($pad / 2);
  margin-right: calc($pad / 2);

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
.sg-border-bottom-new {
  border-bottom: 3px solid #f9f4ed;
}
// .sg-new-hover {
//   transition: all 0.2s;
// }
.sg-new-hover:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
  border-bottom: 3px solid #f6f4f1;
  background-color: #f6f4f1;
}
.sg-hover-move {
  transition: all 0.2s;
  &:hover {
    transform: scale(1.4);
  }
}

.sg-card-4 {
  @extend .sg-card;
  width: calc((100% - #{$pad} * 3) / 4);
  margin-left: calc($pad / 2);
  margin-right: calc($pad / 2);

  &:nth-child(4n + 1) {
    margin-left: 0;
  }

  &:nth-child(4n) {
    margin-right: 0;
  }

  @media screen and(max-width: $width-xs) {
    width: 100%;
    width: calc((100% - #{$pad} * 1) / 2);

    &:nth-child(2n + 1) {
      margin-left: 0;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}

.sg-relative {
  position: relative;
}
.sg-absolute {
  position: absolute;
}
.sg-zindex-100 {
  z-index: 100;
}
.sg-avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;

  @media screen and(max-width: $width-xs) {
    width: 40px;
    height: 40px;
  }
}

.sg-font {
  font-size: $font;
}
.sg-font-sm {
  font-size: $font-sm;
}
.sg-font-icon {
  font-size: $font-icon;
}
.sg-font-icon-lg {
  font-size: $font-icon-lg;
}
.sg-bold {
  font-weight: bold;
}
.sg-light {
  font-weight: lighter;
}
.sg-line-height {
  line-height: 1.6em;
}
.sg-line-height-18 {
  line-height: 1.8em;
}
.sg-line-height-15 {
  line-height: 1.5em;
}
.sg-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.sg-overflow-1 {
  @extend .sg-overflow;
  -webkit-line-clamp: 1;
}
.sg-overflow-2 {
  @extend .sg-overflow;
  -webkit-line-clamp: 2;
}
.sg-link {
  text-decoration: none;
}
.sg-w100 {
  width: 100%;
}
.sg-w25 {
  width: 25%;
}
.sg-h100 {
  height: 100%;
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

@each $name, $size in (xs: 30px, sm: 70px, md: 100px) {
  .sg-img-#{"" + $name} {
    width: $size;
  }
}

.sg-text-center {
  text-align: center;
}
.sg-text-left {
  text-align: left;
}
.sg-center {
  margin-left: auto;
  margin-right: auto;
}
.sg-screen {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.sg-fix-screen {
  @extend .sg-screen;
  position: fixed;
}
.sg-fix-top {
  position: fixed;
  right: 0;
  z-index: 1003;
}
.sg-absolute-screen {
  @extend .sg-screen;
  position: absolute;
}
.sg-relative-screen {
  @extend .sg-screen;
  position: relative;
}
.sg-hide-box .el-dialog__header {
  display: none;
}
.sg-hide {
  display: none;
}
.sg-top-right {
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}
.sg-link-none {
  text-decoration: none;
}
.sg-hover-shadow:hover {
  box-shadow: 4px 4px 4px gray;
}
.sg-shadow {
  box-shadow: 8px 8px 4px gray;
}
.sg-hover-light {
  transition: opacity $trans-time;

  &:hover {
    opacity: 0.6;
  }
}
.sg-hover-zoom-in-sm {
  transition: transform $trans-time;

  &:hover {
    transform: scale(1.03);
  }
}
.sg-hover-zoom-in {
  transition: transform $trans-time;

  &:hover {
    transform: scale(1.05);
  }
}
.sg-min-height-50 {
  min-height: 50px;
}
.sg-fixed-bottom-right {
  position: fixed;
  bottom: $pad;
  right: $pad;
  background: $red;
  padding: $pad;
  box-shadow: 0px 0px calc($pad / 3) $red;
  border-radius: 50%;
  color: white;
}
.sg-hover-gray-bg:hover {
  background: #f6f4f1;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
}
.sg-fade-in:hover {
  animation: grayScaleBox 1s ease-out;
  background-image: url("https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/gray-bg.png");
  background-position: top center;
  background-size: 200% 100%;
  width: 100%;
}
.sg-fade-in.sg-first-year:hover {
  background-image: none;
}

@keyframes grayScaleBox {
  0% {
    background-size: 100% 100%;
  }
  100% {
    background-size: 200% 100%;
  }
}
.sg-web-content-2 {
  padding-top: 60px;
}
.sg-pox-btn {
  position: absolute;
  right: 0;
  top: 68%;
  transform: translateY(-30px);
  z-index: 100;

  .sg-pox-prev {
    float: left;
    width: 80px;
    height: 60px;
    cursor: pointer;
    outline: none;
    outline-color: initial;
    outline-style: none;
    outline-width: initial;
    position: absolute;
    right: 0;
    top: 0;

    &.on:before {
      width: 66px;
    }

    &:before {
      content: "";
      width: 20px;
      height: 100%;
      background: url(https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/prev-arrow.png)
        no-repeat 0;
      position: absolute;
      right: 14px;
      top: 0;
      transition: all 0.3s;
    }
  }

  .sg-pox-next {
    float: left;
    width: 80px;
    height: 60px;
    cursor: pointer;
    outline: none;
    position: absolute;
    left: 0;
    top: 0;

    &.on:before {
      width: 66px;
    }

    &:before {
      content: "";
      width: 20px;
      height: 100%;
      background: url(https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/right-arrow-2.png)
        no-repeat 100%;
      position: absolute;
      left: 14px;
      top: 0;
      transition: all 0.3s;
    }
  }
}
.el-drawer__body::-webkit-scrollbar {
  width: 0 !important;
}
.sg-web-group {
  padding: 0;
  border-radius: 0;
}
body {
  scroll-behavior: smooth;
}
#sg-industry-box {
  .el-carousel__item.is-animating {
    transition: all 1s ease;
  }
}
.sg-nav-ani {
  transition: height 0.5s;
}
</style>
