<template>
  <el-dialog
    title="上传或已上传文件"
    :visible="show"
    width="90%"
    height="90%"
    top="5vh"
    append-to-body
    @close="close"
    class="sg-hide-box"
  >
    <div v-if="showEdit">
      <EditImage
        :src="selectedFile ? selectedFile.url : ''"
        @close="switchShowEdit(false)"
      ></EditImage>
    </div>
    <el-tabs v-model="activeTab" v-show="!showEdit">
      <el-tab-pane label="上传文件" name="upload">
        <div class="sg-upload-box sg-flex-wrap">
          <div
            v-for="(item, index) in files"
            class="sg-upload-item"
            :key="index"
          >
            <img class="sg-upload-img" :src="item" />
          </div>
          <div
            class="sg-upload-item sg-flex-center sg-upload-btn sg-pointer"
            @click="selectFile"
          >
            <i class="el-icon-loading" v-if="loading"></i>
            <i class="el-icon-plus" v-else></i>
            <input
              type="file"
              name="file"
              class="sg-hide"
              @change="change($event)"
              ref="uploadInput"
              :multiple="limit > 1"
            />
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="媒体库" name="library">
        <el-row>
          <el-col :sm="{ span: 18 }">
            <div class="sg-upload-box sg-flex-wrap sg-library sg-pad">
              <div
                v-for="(item, index) in list"
                :class="{
                  'sg-upload-item sg-relative': true,
                  'sg-checked': item.checked,
                }"
                :key="index"
                @click="switchCheck(index)"
              >
                <img class="sg-upload-img" :src="item.url" />
                <i
                  class="el-icon-success sg-absolute sg-top-right sg-check-icon"
                  v-if="item.checked"
                ></i>
              </div>
            </div>
            <div class="sg-pagination">
              <el-pagination
                @current-change="pageChange"
                :current-page="current"
                :page-size="pageSize"
                layout="total, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </el-col>
          <el-col :sm="{ span: 6 }">
            <div
              class="sg-light-gray-bg sg-w100 sg-h100 sg-pad sg-border-box"
              v-if="selectedFile"
            >
              <div>
                <h4>附件详情</h4>
                <div class="sg-flex sg-pad-tb">
                  <img
                    :src="selectedFile.url"
                    class="sg-upload-img sg-margin-right"
                  />
                  <div class="sg-font-sm">
                    <div>
                      <b>{{ selectedFile.name }}</b>
                    </div>
                    <div>{{ selectedFile.size | fileSize }}</div>
                    <div>
                      {{ selectedFile.width }} × {{ selectedFile.height }}
                    </div>
                    <div>{{ selectedFile.date }}</div>
                  </div>
                </div>
              </div>
              <div>
                <el-input
                  size="mini"
                  type="textarea"
                  :rows="3"
                  :readonly="true"
                  placeholder="请输入内容"
                  v-model="selectedFile.url"
                >
                </el-input>
                <el-row class="sg-margin-top sg-space-between">
                  <el-button type="primary" round size="small"
                    >复制链接</el-button
                  >
                  <el-button
                    type="success"
                    round
                    size="small"
                    @click="switchShowEdit(true)"
                    >编辑图片</el-button
                  >
                  <el-button type="danger" round size="small"
                    >删除图片</el-button
                  >
                </el-row>
                <div class="sg-margin-top">
                  <el-divider></el-divider>
                  <h4 class="sg-margin-bottom">插入图片</h4>
                  <el-form size="mini">
                    <el-form-item label="对齐">
                      <el-radio-group v-model="align">
                        <el-radio label="left">左</el-radio>
                        <el-radio label="center">居中</el-radio>
                        <el-radio label="right">右</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label="宽高">
                      <el-radio-group v-model="sizeStyle">
                        <el-radio label="auto">自适应</el-radio>
                        <el-radio label="custom">自定义</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item v-show="sizeStyle == 'custom'">
                      <el-col :span="11">
                        <el-input v-model="width"></el-input>
                      </el-col>
                      <el-col :span="2">
                        <div class="sg-text-center">×</div>
                      </el-col>
                      <el-col :span="11">
                        <el-input v-model="height"></el-input>
                      </el-col>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <el-row type="flex" class="row-bg" justify="end" v-show="!showEdit">
      <el-button type="primary" @click="confirm">确定</el-button>
    </el-row>
  </el-dialog>
</template>
<script>
// import { API_FILE_UPLOAD_URL } from "@/services/env.js";
import EditImage from "@/components/Share/EditImage.vue";

export default {
  name: "SelectFile",
  components: {
    EditImage,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 1,
    },
    showInsert: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      // upload|library
      activeTab: "upload",
      files: [],
      loading: false,
      list: [],
      total: 0,
      pageSize: 15,
      current: 1,
      pre_uri: "upload/",
      showEdit: false,
      align: "center",
      sizeStyle: 'auto',
      width: '',
      height: ''
    };
  },
  computed: {
    selectedFile: function () {
      for (var i = 0; i < this.list.length; ++i) {
        if (this.list[i].checked) return this.list[i];
      }
      return this.list[0];
    },
  },
  methods: {
    getCheckedFileUrls() {
      return this.list.filter((item) => item.checked).map((item) => item.url);
    },
    switchShowEdit(show) {
      this.showEdit = show;
      if (!show) {
        this.search();
      }
    },
    search() {
      this.current = 1;
      this.getList();
    },
    switchCheck(index) {
      if (this.limit <= 1) {
        for (var i = 0; i < this.list.length; ++i) {
          this.list[i].checked = false;
        }
      }
      var item = this.list[index];
      item.checked = item.checked ? !item.checked : true;
      this.$set(this.list, index, item);
    },
    pageChange(current) {
      this.current = current;
      this.getList();
    },
    close() {
      this.files = [];
      this.$emit("close");
    },
    selectFile() {
      this.$refs.uploadInput.click();
    },
    change(e) {
      var that = this
      that.loading = true
      this.$util.uploadFiles(that, e, function(res) {
          that.loading = false;
          if (res.code == 0) {
            that.files = res.data.path.split(",");
            that.search();
          }
      })
    },
    confirm() {
      var files = [];
      if (this.activeTab == "upload") files = this.files;
      else if (this.activeTab == "library") files = this.getCheckedFileUrls();
      if(this.showInsert) {
        if(files.length <= 0) return false;
        var url = files[0]
        var html = '<p' + ' class="sg-editor-img sg-img-' + this.align + '"' + '><img src="' + url + '"'
        if(this.sizeStyle == 'custom') {
          html += ' width=' + this.width + ' height=' + this.height
        }
        html += '></p>';
        this.$emit("confirm", html);
      } else {
        this.$emit("confirm", files);
      }
      
      this.close();
    },
    getList() {
      this.$store.commit("updateLoading", true);
      this.$http
        .post(
          this.pre_uri + "get",
          Object.assign(
            {
              page: this.current,
            },
            this.searchItem
          )
        )
        .then((res) => {
          this.$store.commit("updateLoading", false);
          if (res.code == 0) {
            this.$util.parsePagiList(this, res.data);
          }
        });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/global.scss";
.sg-upload-item.sg-checked,
.sg-library .sg-upload-item:hover {
  border-color: $main-color;
}
.sg-upload-item {
  margin: calc($pad / 2);
  position: relative;
  border: 1px solid $border-color;
}
.sg-upload-item,
.sg-upload-img {
  width: $sg-upload-width;
  height: $sg-upload-width;
  border-radius: 5px;
}
.sg-upload-btn {
  border: 1px dashed $border-color;
  font-size: $font-icon;
}
.sg-check-icon {
  font-size: $font-icon;
  color: $main-color;
}
</style>
