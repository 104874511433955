import Vue from "vue";
import VueRouter from "vue-router";

// import store from '../store'
import util from "../services/util";

Vue.use(VueRouter);

const routes = [
    {
        path: "/admin/login",
        component: () => import("@/views/Admin/Login.vue"),
    },
    {
        path: "/admin/admin-menus",
        name: "AdminMenu",
        component: () => import("@/views/Admin/AdminMenu.vue"),
    },
    {
        path: "/admin/",
        name: "AdminLayout",
        component: () => import("@/components/Admin/Layout.vue")
    },
    {
        path: "/",
        component: () => import("@/components/Web/Layout.vue"),
        children: [
            {
                path: "/",
                component: () => import("@/views/Web/Index.vue"),
            },
            {
                path: "/star/:tab",
                component: () => import("@/views/Web/Star.vue"),
            },
            {
                path: "/industry/:tab",
                component: () => import("@/views/Web/Industry.vue"),
            },
            {
                path: "/hire",
                component: () => import("@/views/Web/Hire.vue"),
            },
            {
                path: "/test",
                component: () => import("@/views/Web/Test.vue"),
            },
            {
                path: "/article-list",
                component: () => import("@/views/Web/ArticleList.vue"),
            },
            {
                path: "/article/:id",
                component: () => import("@/views/Web/Article.vue"),
            },
            {
                path: "/contact",
                component: () => import("@/views/Web/Contact.vue"),
            },
            // {
            //     path: "/categories/:id",
            //     component: () => import("@/views/Web/Category.vue"),
            // },
        ],
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    util.updateMenu(to.path)
    next()
})

export default router;
