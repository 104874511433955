const route = Object.create(null)

route.install = function(Vue) {
    Vue.component('AdminProfile', () => import('@/views/Admin/Profile.vue'))
    Vue.component('AdminHome', () => import('@/views/Admin/Home.vue'))
    Vue.component('AdminSuperUser', () => import('@/views/Admin/AdminUser.vue'))
    Vue.component('AdminUser', () => import('@/views/Admin/User.vue'))
    Vue.component('AdminMenu', () => import('@/views/Admin/AdminMenu.vue'))
    Vue.component('AdminCategory', () => import('@/views/Admin/Category.vue'))
    Vue.component('AdminCarousel', () => import('@/views/Admin/Carousel.vue'))
    Vue.component('AdminArticle', () => import('@/views/Admin/Article.vue'))
    Vue.component('AdminUpload', () => import('@/views/Admin/Upload.vue'))
}

export default route