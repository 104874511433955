<template>
  <div
    class="sg-preview-box sg-fix-screen sg-gray-mask-bg sg-border-box"
    v-if="show"
  >
    <i
      class="el-icon-close sg-icon-btn sg-pointer sg-absolute sg-preview-close"
      @click="close"
    ></i>
    <i
      class="el-icon-arrow-left sg-icon-btn sg-pointer sg-absolute sg-preview-prev"
      @click="updateIndex(index - 1)"
    ></i>
    <i
      class="el-icon-arrow-right sg-icon-btn sg-pointer sg-absolute sg-preview-next"
      @click="updateIndex(index + 1)"
    ></i>
    <div class="sg-action-bottom sg-absolute">
      <i
        class="el-icon-zoom-out sg-pad-lr sg-pointer"
        @click="zoom(scale - 0.2)"
      ></i>
      <i
        class="el-icon-zoom-in sg-pad-lr sg-pointer"
        @click="zoom(scale + 0.2)"
      ></i>
      <i
        :class="{
          'sg-pad-lr sg-pointer': true,
          'el-icon-full-screen': previewType == 'origin',
          'el-icon-c-scale-to-original': previewType == 'fullscreen',
        }"
        @click="switchPreviewType"
      ></i>
      <i
        class="el-icon-refresh-left sg-pad-lr sg-pointer"
        @click="rotate(degree - 90)"
      ></i>
      <i
        class="el-icon-refresh-right sg-pad-lr sg-pointer"
        @click="rotate(degree + 90)"
      ></i>
    </div>
    <div class="sg-flex-center sg-h100 sg-w100">
      <img
        :class="{
          'sg-origin': previewType == 'origin',
          'sg-fullscreen': previewType == 'fullscreen',
        }"
        :src="files[index]"
        :style="{
          transform: 'scale(' + scale + ') rotate(' + degree + 'deg)',
          'margin-left': offsetX + 'px',
          'margin-top': offsetY + 'px',
        }"
        @mousedown="updateMouseStatus($event, 'down')"
        @mouseup="updateMouseStatus($event, 'up')"
        @mousemove="move($event)"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "PreviewCard",
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      scale: 1,
      degree: 0,
      // origin|fullscreen
      previewType: "origin",
      initX: 0,
      initY: 0,
      offsetX: 0,
      offsetY: 0,
      mouseStatus: "up",
    };
  },
  methods: {
    move(e) {
      if (this.mouseStatus == "down") {
        this.offsetX = e.clientX - this.initX;
        this.offsetY = e.clientY - this.initY;
      }
    },
    reset() {
      this.degree = 0;
      this.scale = 1;
      this.offsetX = 0;
      this.offsetY = 0;
    },
    switchPreviewType() {
      this.previewType = this.previewType == "origin" ? "fullscreen" : "origin";
      this.reset()
    },
    rotate(degree) {
      degree = (degree + 360) % 360;
      this.degree = degree;
    },
    zoom(scale) {
      scale = scale > 0.2 && scale < 2 ? scale : this.scale;
      this.scale = scale;
    },
    updateIndex(newIndex) {
      this.reset()
      var len = this.files.length;
      var index = parseInt(newIndex + len) % len;
      this.$emit("updateIndex", index);
    },
    close() {
      this.$emit("close");
    },
    updateMouseStatus(e, status) {
      this.mouseStatus = status;
      if (status == "down") {
        this.initX = e.clientX - this.offsetX;
        this.initY = e.clientY - this.offsetY;
      }
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/global.scss";
$width: 120px;
$font-icon: 25px;
.sg-upload-img,
.sg-upload-wrap,
.sg-upload-item {
  width: $width;
  height: $width;
  border-radius: 5px;
}
.sg-upload-item {
  margin: calc($pad / 2);
  position: relative;
}
.sg-upload-img,
.sg-upload-wrap {
  position: absolute;
  left: 0;
  right: 0;
}
.sg-upload-wrap {
  visibility: hidden;
}
.sg-upload-item:hover .sg-upload-wrap {
  visibility: visible;
}
.sg-icon-actions {
  font-size: $font-icon;
}
.sg-preview-box {
  z-index: 2000;
}
.sg-action-bottom,
.sg-icon-btn {
  font-size: 30px;
  color: white;
}
.sg-icon-btn {
  background: $icon-gray-bg;
  border-radius: 50%;
  padding: calc($pad / 2);
}
.sg-action-bottom {
  background: $icon-gray-bg;
  padding: calc($pad / 2);
  border-radius: 30px;
}
.sg-icon-btn:hover,
.sg-action-bottom i:hover {
  color: $main-color;
}
.sg-action-box {
  padding: $pad * 3;
}
.sg-preview-close {
  top: $pad * 3;
  right: $pad * 3;
}
.sg-preview-next,
.sg-preview-prev {
  top: 50%;
  transform: translateY(-50%);
}
.sg-preview-prev {
  left: $pad * 3;
}
.sg-preview-next {
  right: $pad * 3;
}
.sg-action-bottom {
  left: 50%;
  transform: translateX(-50%);
  bottom: $pad * 3;
}
.sg-preview-close,
.sg-preview-next,
.sg-preview-prev,
.sg-action-bottom {
  z-index: 2000;
}
.sg-origin {
  max-height: 100%;
  max-width: 100%;
  user-select: none;
  -webkit-user-drag: none;
}
</style>
