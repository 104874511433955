const parsePagiList = (that, data) => {
  that.list = data.data
  that.total = data.total
}

const formatDate = (val, fmt = 'yyyy-MM-dd hh:mm:ss') => {
  if (!val) return ''
  var date = new Date(val)
  var o = {
    "M+": date.getMonth() + 1, //月份 
    "d+": date.getDate(), //日 
    "h+": date.getHours(), //小时 
    "m+": date.getMinutes(), //分 
    "s+": date.getSeconds(), //秒 
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度 
    "S": date.getMilliseconds() //毫秒 
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}

const validatePhone = (val) => {
  return /^1[34578]\d{9}$/.test(val)
}

const fileSize = (val) => {
  if (val > 1024 * 1024) return '' + Math.round(val / (1024 * 1024) * 10) / 10 + ' MB';
  if (val > 1024) return '' + Math.round(val / 1024 * 10) / 10 + ' KB';
  return '' + val + ' B'
}

const getBlanks = (cnt) => {
  var str = ''
  for (var i = 0; i < cnt; ++i) str = str + '--'
  return str;
}

const treeToOption = (items, cnt = 0) => {
  var list = []
  for (var i = 0; i < items.length; ++i) {
    list.push({
      id: items[i].id,
      name: getBlanks(cnt) + items[i].name
    })
    if ('children' in items[i]) {
      list = list.concat(treeToOption(items[i].children, cnt + 1))
    }
  }
  return list;
}

const inArray = (arr, item) => {
  return arr.indexOf(item) !== -1 ? true : false;
}

const getList = (that, url, data, loading) => {
  if(loading) that.$store.commit("updateLoading", true);
  that.$http.post(url, data).then((res) => {
    that.$store.commit("updateLoading", false);
    if (res.code == 0) {
      parsePagiList(that, res.data);
    }
  });
}

const getList2 = (that, url, data, loading) => {
  if(loading) that.$store.commit("updateLoading", true);
  that.$http2.post(url, data).then((res) => {
    that.$store.commit("updateLoading", false);
    if (res.code == 0) {
      parsePagiList(that, res.data);
    }
  });
}

const getData2 = (that, url, data, name, cb = null) => {
  that.$http2.post(url, data).then((res) => {
    if (res.code == 0) {
      that[name] = res.data
      typeof cb == 'function' && cb(res)
    }
  });
}

const webAvatar = (avatar) => {
  return avatar ? avatar : 'https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/logo.png'
}

export default {
  parsePagiList,
  formatDate,
  validatePhone,
  fileSize,
  treeToOption,
  inArray,
  getList,
  getList2,
  getData2,
  webAvatar
}